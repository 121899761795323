<template>
  <v-card flat tile :loading="loading">
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="absences"
      disable-pagination
      hide-default-footer
      sort-by="cause.code"
    >
      <template v-slot:item.cause.code="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              {{ item.cause.code }}
            </span>
          </template>
          <span>{{ item.cause.description }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.startDate="{ item }">
        <DateValue :value="item.startDate" />
      </template>
      <template v-slot:item.endDate="{ item }">
        <DateValue :value="item.endDate" />
      </template>
      <template v-slot:item.days="{ item }">
        {{ dateDiff(item.startDate, item.endDate) + 1 }}
      </template>
      <template v-slot:item.substitutes="{ item }">
        <v-chip
          v-for="substitute in item.substitutes"
          :key="item.id + '_' + substitute.id"
          :to="{ name: 'Person', params: { id: substitute.id.toString() } }"
        >
          <v-avatar left>
            <PortraitImage :value="substitute" />
          </v-avatar>
          <span>
            {{ substitute.code ? substitute.code : "" }}
            <PersonName :value="substitute" v-if="!substitute.code" />
          </span>
        </v-chip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { saveChildRoute } from "@/router/helper";
import { dateDiff } from "common/utils/date.js";
import DateValue from "common/components/DateValue.vue";
import PersonName from "common/components/PersonName";
import PortraitImage from "common/components/PortraitImage";

export default {
  name: "AbsencesTeacher",
  components: {
    DateValue,
    PersonName,
    PortraitImage,
  },
  props: {
    id: { type: String },
  },
  computed: {
    personId() {
      return Number.parseInt(this.id);
    },
  },
  data() {
    return {
      absences: [],
      headers: [
        {
          text: "Grund",
          value: "cause.code",
        },
        { text: "von", value: "startDate" },
        { text: "bis", value: "endDate" },
        { text: "Tage", value: "days" },
        { text: "Kommentar", value: "comment" },
        { text: "Stvs", value: "substitutes" },
      ],
      loading: false,
      schoolYear: null,
    };
  },
  methods: {
    dateDiff,
    async fetchData() {
      if (this.personId) {
        this.loading = true;
        this.absences = await this.apiList({
          resource: "teacher/absence",
          query: "teacher=" + this.personId,
        });
        this.loading = false;
      }
    },
  },
  watch: {
    async id() {
      await this.fetchData();
    },
  },
  async created() {
    await this.fetchData();
  },
  beforeRouteUpdate(to, from, next) {
    saveChildRoute(to, next);
  },
};
</script>
